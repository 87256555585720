.ui-subheading {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase
}

@media screen and (min-width: 640px) {
  .ui-subheading {
    font-size: 1.2rem
  }
}
