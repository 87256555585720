@import "~angular-notifier/styles";

.error-message {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 100%;
  color: #000;

  p {
    margin: 0 20px;
  }

  a {
    margin-top: 20px;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.notifier__notification,
.notifier__notification-message {
  color: #fff !important;
}

.text-center {
  text-align: center;
}

.no-list-style {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

.clearfix {
  clear: both;
}

.hidden {
  display: none;
}

.loader {
  position: absolute;
  top: 35%;
  margin: 0 auto;
  width: 100%;
  color: #000;

  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}


.theme-editor-action-list {
  margin: 0;
  // max-height: calc(100vh - 80px);
  overflow: hidden;
  overflow-y: auto;
}

.theme-editor-action-list-item {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  display: block;
  color: #212b36;
  background: white;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 0;
  white-space: normal;
  -webkit-transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  text-align: left;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-top: 1px solid #dfe3e8;
  border-color: #dfe3e8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  &:hover {
    background: white;
    color: #31373d;
    text-decoration: none;
    -webkit-box-shadow: inset -4px 0 0 0 #5c6ac4;
    box-shadow: inset -4px 0 0 0 #5c6ac4;
    outline: 0;
  }
}

.theme-editor-action-list-item-content {
  padding-right: 44px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.theme-editor-action-list-item-icon {
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin-right: 0.8rem;
}

.theme-editor-action-list-item-icon svg {
  display: inline-block;
  vertical-align: middle;
  color: #dfe3e8;
  fill: #454f5b;
  top: auto;
  width: 20px;
  height: 20px;
  margin: 10px;
}

.theme-editor-action-list-item-text {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
}

.theme-editor-action-list-item-link {
  color: #5c6ac4;
  text-align: left;
}

.theme-editor-action-list-item-link svg {
  fill: #5c6ac4;
}

.theme-editor-action-list-item-dragable {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 44px;
  cursor: -webkit-grab;
  cursor: grab;
}

.theme-editor-action-list-item-dragable .icon-drag-handle {
  position: absolute;
  right: 20px;
}

input,
.autocomplete-field {
  font-size: 14px;
  line-height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
}

/*Input fields*/
input,
.autocomplete-field,
textarea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  vertical-align: top;
  height: 28px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  padding: 4px;
  margin: 0;
  border: 1px solid #d3dbe2;
  display: inline-block;
  color: #222;
  border-radius: 3px;
}




.ampify-editor--range__slider {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: none
}

.ampify-editor--range__slider::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 8px;
  background: #dfe3e8;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15)
}

.ampify-editor--range__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #95a7b7;
  cursor: -webkit-grab;
  cursor: grab;
  -webkit-transition: border 0.05s ease;
  transition: border 0.05s ease;
  margin-top: calc(8px / 2 - 20px / 2)
}

.ampify-editor--range__slider::-webkit-slider-thumb:hover {
  border: 2px solid #5c6ac4
}

.ampify-editor--range__slider::-moz-range-track {
  border: inherit;
  background: transparent;
  border-radius: 8px;
  background: #dfe3e8;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  height: 8px
}

.ampify-editor--range__slider::-moz-focus-outer {
  border: 0
}

.ampify-editor--range__slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #95a7b7;
  cursor: grab;
  -webkit-transition: border 0.05s ease;
  transition: border 0.05s ease
}

.ampify-editor--range__slider::-moz-range-thumb:hover {
  border: 2px solid #5c6ac4
}

.ampify-editor--range__slider::-ms-tooltip {
  display: none
}

.ampify-editor--range__slider::-ms-track {
  height: 8px;
  background: transparent;
  border-color: transparent;
  color: transparent
}

.ampify-editor--range__slider::-ms-fill-lower {
  background: #dfe3e8;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15)
}

.ampify-editor--range__slider::-ms-fill-upper {
  background: #dfe3e8;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15)
}

.ampify-editor--range__slider::-ms-thumb {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #95a7b7;
  cursor: grab;
  -webkit-transition: border 0.05s ease;
  transition: border 0.05s ease
}

.ampify-editor--range__slider::-ms-thumb:hover {
  border: 2px solid #5c6ac4
}

.ampify-editor--range__slider:hover::-webkit-slider-thumb {
  border: 2px solid #5c6ac4
}

.ampify-editor--range__slider:hover::-moz-range-thumb {
  border: 2px solid #5c6ac4
}

.ampify-editor--range__slider:hover::-ms-thumb {
  border: 2px solid #5c6ac4
}

.ampify-editor--range__slider:focus::-webkit-slider-thumb {
  border: 2px solid #5c6ac4
}

.ampify-editor--range__slider:focus::-moz-range-thumb {
  border: 2px solid #5c6ac4
}

.ampify-editor--range__slider:focus::-ms-thumb {
  border: 2px solid #5c6ac4
}


.ampify-editor--range__slider {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
}




.aspect-ratio {
  position: relative;
  display: block;
  background: #fafbfc;
  padding: 0;
}

.aspect-ratio--square {
  width: 100%;
  padding-bottom: 100%;
}

.aspect-ratio--square {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}




.ui-button,
.btn,
.fresh-ui .action-bar__more--has-many .ui-button--transparent {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: inherit;
  font-family: inherit;
  font-weight: 400;
  cursor: pointer;
  text-transform: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.9rem 1.5rem;
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(#f9fafb));
  background: linear-gradient(to bottom, white, #f9fafb);
  border: 1px solid #c4cdd5;
  -webkit-box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: #212b36;
  -webkit-transition-property: background, border, -webkit-box-shadow;
  transition-property: background, border, -webkit-box-shadow;
  transition-property: background, border, box-shadow;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  -webkit-transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent
}

.ui-button::-moz-focus-inner,
.btn::-moz-focus-inner,
.fresh-ui .action-bar__more--has-many .ui-button--transparent::-moz-focus-inner {
  padding: 0;
  border: 0
}

.ui-button:hover,
.ui-button:focus,
.ui-button:active,
.btn:hover,
.fresh-ui .action-bar__more--has-many .ui-button--transparent:hover,
.btn:focus,
.fresh-ui .action-bar__more--has-many .ui-button--transparent:focus,
.btn:active,
.fresh-ui .action-bar__more--has-many .ui-button--transparent:active {
  text-decoration: none
}

.ui-button.btn-disabled,
.ui-button.disabled,
.ui-button:disabled,
.ui-button[disabled],
.btn.btn-disabled,
.fresh-ui .action-bar__more--has-many .btn-disabled.ui-button--transparent,
.btn.disabled,
.fresh-ui .action-bar__more--has-many .disabled.ui-button--transparent,
.btn:disabled,
.fresh-ui .action-bar__more--has-many .ui-button--transparent:disabled,
.btn[disabled],
.fresh-ui .action-bar__more--has-many [disabled].ui-button--transparent {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  pointer-events: none
}

@media screen and (min-width: 640px) {

  .ui-button,
  .btn,
  .fresh-ui .action-bar__more--has-many .ui-button--transparent {
    font-size: 1.4rem
  }
}

.ui-button .next-icon,
.ui-button .next-icon__text,
.btn .next-icon,
.fresh-ui .action-bar__more--has-many .ui-button--transparent .next-icon,
.btn .next-icon__text,
.fresh-ui .action-bar__more--has-many .ui-button--transparent .next-icon__text {
  position: relative;
  top: -0.04em;
  margin-top: -1.1rem;
  margin-bottom: -1.1rem
}

.ui-button .next-icon--color-blue,
.btn .next-icon--color-blue,
.fresh-ui .action-bar__more--has-many .ui-button--transparent .next-icon--color-blue {
  color: transparent;
  fill: #212b36
}

.ui-button .next-icon--color-white,
.btn .next-icon--color-white,
.fresh-ui .action-bar__more--has-many .ui-button--transparent .next-icon--color-white {
  color: transparent;
  fill: white
}

.ui-button .ico,
.btn .ico,
.fresh-ui .action-bar__more--has-many .ui-button--transparent .ico {
  margin-top: -button-vertical-spacing(large)-4px;
  margin-bottom: -button-vertical-spacing(large);
  vertical-align: middle
}

.ui-button:hover,
.btn:hover,
.fresh-ui .action-bar__more--has-many .ui-button--transparent:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#f9fafb), to(#f4f6f8));
  background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
  border-color: #c4cdd5;
  -webkit-box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  color: #212b36
}

.ui-button:focus,
.btn:focus,
.fresh-ui .action-bar__more--has-many .ui-button--transparent:focus {
  border-color: #5c6ac4;
  outline: 0;
  -webkit-box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 1px #5c6ac4;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 1px #5c6ac4
}

.ui-button:active,
.ui-button[aria-pressed='true'],
.btn:active,
.fresh-ui .action-bar__more--has-many .ui-button--transparent:active,
.btn[aria-pressed='true'],
.fresh-ui .action-bar__more--has-many [aria-pressed='true'].ui-button--transparent {
  background: -webkit-gradient(linear, left top, left bottom, from(#f4f6f8), to(#f4f6f8));
  background: linear-gradient(to bottom, #f4f6f8, #f4f6f8);
  border-color: #c4cdd5;
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2);
  box-shadow: inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2)
}

.ui-button[aria-pressed='true']:hover,
.btn[aria-pressed='true']:hover,
.fresh-ui .action-bar__more--has-many [aria-pressed='true'].ui-button--transparent:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#eff2f5), to(#eff2f5));
  background: linear-gradient(to bottom, #eff2f5, #eff2f5)
}

.ui-button[aria-pressed='true']:focus,
.btn[aria-pressed='true']:focus,
.fresh-ui .action-bar__more--has-many [aria-pressed='true'].ui-button--transparent:focus {
  border-color: #5c6ac4;
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2), 0 0 0 1px #5c6ac4;
  box-shadow: inset 0 1px 1px 0 rgba(99, 115, 129, 0.1), inset 0 1px 4px 0 rgba(99, 115, 129, 0.2), 0 0 0 1px #5c6ac4
}

.ui-button[aria-pressed='true']:active,
.btn[aria-pressed='true']:active,
.fresh-ui .action-bar__more--has-many [aria-pressed='true'].ui-button--transparent:active {
  background: -webkit-gradient(linear, left top, left bottom, from(#ebeef2), to(#ebeef2));
  background: linear-gradient(to bottom, #ebeef2, #ebeef2);
  border-color: #c4cdd5;
  -webkit-box-shadow: inset 0 2px 1px 0 rgba(99, 115, 129, 0.1), inset 0 2px 4px 0 rgba(99, 115, 129, 0.2);
  box-shadow: inset 0 2px 1px 0 rgba(99, 115, 129, 0.1), inset 0 2px 4px 0 rgba(99, 115, 129, 0.2)
}

.ui-button--full-width,
.btn--full-width {
  overflow: hidden;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
}

.theme-editor-action-list__item {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  display: block;
  color: #212b36;
  background: white;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 0;
  white-space: normal;
  -webkit-transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  text-align: left;
}

.theme-editor-action-list--compact .theme-editor-action-list__item {
  padding: 5px 20px;
}

.ui-popover {
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  display: none;
  margin: 0.8rem 1.6rem;
  background-color: rgba(255, 255, 255, 0.98);
  -webkit-box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
  border-radius: 3px;
  max-width: 100%;
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
}

.ui-popover--is-active {
  -webkit-transform: scale(1);
  transform: scale(1);
  display: block;
  opacity: 1;
}



.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow:
    inset -4px 0 0 0 #5c6ac4,
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.plain-list {
  list-style: none;
  margin: 0;

  li {
    list-style: none;
  }
}




.btn-add-content {
  display: flex;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  color: #5c6ac4;
  background: white;
  border: 0;
  box-shadow: none;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 0;
  white-space: normal;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  text-align: left;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
  border-color: #dfe3e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .icon {
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin-right: 0.8rem;
    padding-top: 10px;
    padding-left: 10px;
  }

  .label {
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 40px;
  }

  svg {
    fill: #5c6ac4;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    top: auto;
  }
}


.ui-card {
  background-color: white;
  -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 3px;
}

.ui-card-section {
  background-color: #f9fafb;
  padding: 3.2rem;
}


/*Pick items preview*/
.pick-items-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  height: 100vh;
  z-index: 9;
  background: #f9fafb;
}

.pick-items-preview .header {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  background: #f9fafb;
  -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  color: #212529;
  height: 5.6rem;
  z-index: 2;
}

.pick-items-preview .header h2 {
  padding: 15px 40px;
  cursor: default;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
}

.pick-items-preview .close {
  background: transparent;
  border: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  padding: 0;
  color: #95a7b7;
  right: 0;
  cursor: pointer;
}

.pick-items-preview .close i {
  top: 10px;
}



.button-group.two {
  margin: 0;
  margin-top: -3px;
  display: block;
  text-align: center;
  border-top: 1px solid rgb(196, 205, 213);

  .first-button {
    border-right: 1px solid rgb(196, 205, 213) !important;
  }

  .button {
    float: none;
    width: 50%;
    border: 0;
  }
}


.Polaris-Button--primary,
.Polaris-Page .Polaris-Button--primary {
  background: #2898fb;
  border-color: #c1e0fb;
  box-shadow: inset 0 1px 0 0 #0068c4;
  color: #fff;

  &:hover {
    background: darken(#2898fb, 10%);
    border-color: #3f4eae;
    color: #fff
  }

  &:active {
    background: darken(#2898fb, 10%);
    border-color: #3f4eae;
    color: #fff
  }

  &:focus {
    background: darken(#2898fb, 10%);
    border-color: #3f4eae;
    color: #fff
  }
}


.Polaris-Page p,
.Polaris-Page__Content p,
.onboarding-main p {
  margin: 0;
  color: #1a1a1a;
}

.Polaris-Page button {
  box-shadow: none;

  &:hover {
    background: transparent;
    box-shadow: none;
    border: .1rem solid var(--p-border-on-surface, #c4cdd5);
    color: #1a1a1a;
  }

  &:active {
    background: transparent;
    box-shadow: none;
    border: .1rem solid var(--p-border-on-surface, #c4cdd5);
    color: #1a1a1a;
  }

  &:focus {
    background: transparent;
    box-shadow: none;
    border: .1rem solid var(--p-border-on-surface, #c4cdd5);
    color: #1a1a1a;
  }
}

.Polaris-Pagination__Centered {
  margin: 0 auto;
}

.device-smaller-7-wrapper {
  margin: -100px;

  .device-smaller-7 {
    margin: 0 auto;
    transform: scale(0.7);
  }
}


.upgrade-label {
  text-decoration: none;
  cursor: pointer;
  color: #007ace;
}


.Polaris-Modal-Dialog__Container {
  text-align: left;
}



.color-picker-input {
  width: 80px;
  min-width: 80px;

  input {
    border: .1rem solid #c4cdd5;
    cursor: pointer;
  }
}



/* DATE TIME PICKER */
.date-picker {
  .ngb-dp-arrow {
    width: 40px;
    height: 32px;

    .btn {
      background: transparent !important;
      background-color: transparent !important;
    }
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }

  [ngbDatepickerDayView] {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
}

.time-picker {
  .ngb-tp-input-container {
    width: 64px;

    input {
      width: 64px;
      min-width: 20px;
    }
  }
}


.modal-content {
  .close {
    background: transparent;
    background-color: transparent;
    box-shadow: none;
  }
}

.Polaris-Page {

  .Polaris-Button--plain:hover,
  .Polaris-Button--plain:focus,
  .Polaris-Tabs__Tab:hover,
  .Polaris-Tabs__Tab:focus {
    border: none;
  }
}

.Polaris-Modal-Dialog__Container {
  .Polaris-Button:hover {
    color: black;
  }
}

.Polaris-Modal-CloseButton {
  background-color: white;
  color: black;
  fill: black;
}
